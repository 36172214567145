<template>
  <div>
    <h1 style="text-align: center">Notes</h1>
    <v-list>
      <v-list-item-group>
        <v-list-item>Hello</v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
name: "Notes"
}
</script>

<style scoped>

</style>